import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';
import { get as getProjection, fromLonLat } from 'ol/proj';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import WMTS from 'ol/source/WMTS';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image.js';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import View from 'ol/View';

export function initMap(center, zoom, opts) {
    proj4.defs('EPSG:25833', '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs');
    register(proj4);
    var projection = getProjection('EPSG:25833');
    var wmts = {
        grid: {
            extent:  [-291244,6377360,1308756,7977360],
            resolutions: new Array(15),
            matrixIds: new Array(15),
            origins: new Array(15)
        },
        attributions: 'Kartverket',
        serverUrl: 'https://geo.stur.no/geoserver/gwc/service/wmts',
        layerName: opts.background,
        style: '',
        matrixSet: 'UTM-33-Norge',
        format: 'image/png'
    };
    var resolution = 6250.0;
    var originY;
    for (var j = 0; j < 15; j++) {
        wmts.grid.matrixIds[j] = "UTM-33-" + j;
        originY = wmts.grid.extent[1] + (resolution * 256) 
                    * Math.ceil((wmts.grid.extent[3]-wmts.grid.extent[1]) / (resolution * 256));
        wmts.grid.origins[j] = [wmts.grid.extent[0], originY];
        wmts.grid.resolutions[j] = resolution;
        resolution = resolution / 2.0;
    }
    var wmtsSource = new WMTS({
        attributions: wmts.attributions,
        url: wmts.serverUrl,
        layer: wmts.layerName,
        style: wmts.style,
        matrixSet: wmts.matrixSet,
        format: wmts.format,
        tileGrid: new WMTSTileGrid({
                        extent: wmts.grid.extent,
                        origins: wmts.grid.origins,
                        resolutions: wmts.grid.resolutions,
                        matrixIds: wmts.grid.matrixIds
        })
    });
    var mosaicSource = new ImageWMS({
        url: 'https://geo.stur.no/geoserver/wms',
        params:{
          'LAYERS': 'stur:mosaic_boundary',
          'FORMAT': 'image/png'
        }
    });
    var turSource = new ImageWMS({
        url: 'https://geo.stur.no/geoserver/wms',
        params:{
            'LAYERS': opts.turFriluft,
            'FORMAT': 'image/png'
        }
    });
    var map = new Map({
        target: 'map',
        layers: [
            new TileLayer({
                opacity: 1,
                source: wmtsSource
              }),
              new ImageLayer({
                  source: turSource,
                  visible: true
              }),
            new ImageLayer({
                source: mosaicSource,
                visible: false
            })
        ],
        controls: [],
        view: new View({
            center: fromLonLat(center, projection),
            zoom: zoom,
            projection: projection
        })
    });

    map.switchMosaicVisibility = function() {
        var num = map.getAllLayers().length - 1;
        for (var i = num; i > 0; --i) {
            var layer = map.getAllLayers().at(i);
            var src = layer.getSource();
            if (src.getParams 
                    && src.getParams().LAYERS === "stur:mosaic_boundary") {
                layer.setVisible(!layer.getVisible());
                return;
            }
        }
    }
    return map;
}
window.initMap = initMap;
