
        var filepaths = [];
        var activeLayer;
        var activeData;
        var activeOpts;

        var colors = ['#669933', '#3366FF', '#FF33FF'];
        var iCol = 0;

        /** Act when returning from upload file. Add file to list and load data from track. */
        var responseFileUpload = async function() {
            if (this.readyState === 4) {
                document.getElementById("drop-zone").className = "ready";
                if (this.status === 200) {
                    let path = this.responseText;
                    filepaths.pop(path);
                    var elem = document.getElementById("files");
                    var i = path.indexOf("/");
                    addFileTag(elem, path.substring(0, i), path.substring(i + 1));

                    var data = await loadTracks(path)
                    while (!data.ready) {
                        data = await loadTracks(path);
                    }
                    var n = data.ids.length;
                    if (n > 0) {
                        await loadData(data.ids[n - 1])
                                 .then(drawTrack);
                    }
                } else {
                    console.log("Upload gave code:" + this.status 
                            + " and message: " + this.responseText);
                }
            }
        }

        /** Load tracks within gpx file */
        var loadTracks = async function(path) {
            return await fetch("/api/files/" + path 
                    + "/tracks").then(resp => resp.json());
        }

        /**  Load data for another gpx file. */
        var changeGraph = async function(path) {
            var data = await loadTracks(path);
            var n = data.ids.length;
            if (n > 0) {
                await loadData(data.ids[n - 1])
                         .then(drawTrack);
            }
        }

        /** Sending POST with the content of the file to /api/files */
        var uploadFile = function(file) {
            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = responseFileUpload;
            xhr.open("POST", "/api/files");

            var data = new FormData();
            data.append("file", file);
            xhr.send(data);
        }

        /** Event handler for file drop in element. Calling uploadFile. */
        var dropHandler = function(event) {
            event.preventDefault();
            document.getElementById("drop-zone").className = "working";
            if (event.dataTransfer.items) {
                for (let item of event.dataTransfer.items) {
                    if (item.kind === 'file') {
                        let file = item.getAsFile();
                        console.log("A:" + file.name);
                        uploadFile(file);
                    }
                }
            } else {
                for (let file of event.dataTransfer.files) {
                    console.log("B:" + file.name);
                    uploadFile(file);
                }
            }
        }

        /** Send request for api/login, then for /api/files if workFolder is set */
        var doLogin = async function() {
            var workFolder;
            await fetch("/api/login")
                    .then(resp => resp.ok ? resp.json() : Promise.reject("No login"))
                    .then(json => {
                        if (json.workFolder) {
                            workFolder = json.workFolder;
                            var path = "/api/files/" + workFolder;
                            return fetch(path);
                        } else {
                            Promise.reject("no work folder")
                        }
                    })
                    .then(resp => resp.ok ? resp.json() : Promise.reject("Failed to get file list"))
                    .then(files => {
                        var elem = document.getElementById("files");
                        for (var child of elem.children) {
                            elem.removeChild(child);
                        }
                        for (const f of files) {
                            addFileTag(elem, workFolder, f);
                        }
                    })
                    .catch(err => console.log(err));

        }

        /** Add a html element for a gpx file */
        var addFileTag = function(parent, workFolder, filename) {
            var newElem = document.createElement("A");
            newElem.innerText = filename;
            const path = workFolder + "/" + filename;
            newElem.addEventListener('click', () => changeGraph(path));
            parent.appendChild(newElem);
        }
        /** Draw track as a plot and layer using data. First clear old drawings. */
        var drawTrack = function(data) {
            var elem = document.getElementById("graph");
            for (var child of elem.children) {
                elem.removeChild(child);
            }
            if (activeLayer) {
                MAIN_MAP.removeLayer(activeLayer)
            }
            var opts = {color: colors[iCol++]};
            drawGraph(data, opts);
            activeLayer = addLayer(MAIN_MAP, data.features, opts);
            activeData = data;
            activeOpts = opts;
            if (iCol >= colors.length) iCol = 0;
        }

        var doKeyPress = function(evt) {
            if (evt.ctrlKey && evt.key === 'm') {
                MAIN_MAP.switchMosaicVisibility();
            }
        }

        var openFile = function() {
            var inp = document.getElementById("file-input");
            var evt = document.createEvent("MouseEvents");
            evt.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
            inp.dispatchEvent(evt);
        }

        /**
         * Called by change event of file input
         */
        var readFile = function(evt) {
            var file = evt.target.files[0];
            if (!file) {
                return;
            }
            uploadFile(file);
        }

        const MAIN_MAP = initMap([10.8434338, 59.98806125], 10, {
            background: 'no.geonorge:Kartdata',
            turFriluft: 'SykkelRuter'
        });
        new ResizeObserver(() => {
            if (activeData) {
                var elem = document.getElementById("graph");
                for (var child of elem.children) {
                    elem.removeChild(child);
                }
                drawGraph(activeData, activeOpts);
            }
        }).observe(document.getElementById("graph"));

        window.addEventListener('load', doLogin);
        window.addEventListener('keydown', doKeyPress);
        document.getElementById('drop-zone').addEventListener('drop', dropHandler);
        document.getElementById('file-button').addEventListener('click', openFile);
        document.getElementById('file-input').addEventListener('change', readFile);
    