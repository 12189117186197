import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector.js';
import Feature from 'ol/Feature.js';
import VectorLayer from 'ol/layer/Vector.js';
import WKT from 'ol/format/WKT.js';
import {Circle, Fill, Stroke, Style} from 'ol/style.js';
import {getCenter} from 'ol/extent';
import LineString from 'ol/geom/LineString.js';

/**
 * Add a track as a new VectorLayer within the map.
 * Then start a zoom-in to the extent of that track.
 * 
 * @param {Map} map The map object to add the layer into
 * @param {Array<*>} points The points as a collection of obj.point where point could be handled as a Geometry
 */
export function addLayer(map, points, opts) {
    var features = [];
    var format = new WKT();
    for (var pnt of points) {
        features.push(new Feature({
            geometry: format.readGeometry(pnt.point),
            properties: pnt
        }));
    }
    var fill = (opts ? 
        new Fill({color: (opts.color ? opts.color : '#3399CC')}) : 
        new Fill({color: '#3399CC'}));

    
    var layer = new VectorLayer({
        source: new VectorSource(),
        style: new Style({
            image: new Circle({
                fill: fill,
                radius: 3.5
            })
        })
    });
    map.addLayer(layer);
    layer.getSource().addFeatures(features);
    var extent = layer.getSource().getExtent();
    var centerA = map.getView().getCenter();
    var centerB = getCenter(extent);
    var distance = new LineString([centerA, centerB]).getLength();

    map.getView().fit(extent, {
        duration: distance * 0.05,
        padding: [10, 10, 300, 150]
    });
    return layer;
}
window.addLayer = addLayer;