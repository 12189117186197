import {select, scaleLinear, axisBottom, axisLeft, csv} from 'd3';


/** Load data for the track id from a csv into an object with x,y,features. */
export async function loadData(track) {
    var x = [];
    var y = [];
    var features = [];
    await csv("/api/tracks/" + track,
        (d) => {
            x.push(parseFloat(d.dist));
            y.push(parseFloat(d.hoh));
            features.push(d);
        });
    return {
        x: x,
        y: y,
        features: features
    };
}


/** Draw graph into element #graph using data x and y 
 * 
 * @param {Array<*>} data The graph data presented as an array of obj.x and obj.y
 * @param {*} opts The options used for drawing the points
*/
export function drawGraph(data, opts) {
    var element = select("#graph");
    var rect = element.node().getBoundingClientRect();
    var width = Math.round(rect.width * 0.9);
    var margin = rect.width - width;
    var outer = Math.round(margin * 0.4);
    var height = rect.height - margin;
    var svg = element
            .append("svg")
                .attr("width", width + margin)
                .attr("height", height + margin)
            .append("g")
                .attr("transform", 
                    "translate(" + (margin - outer) + "," + outer + ")");
    
    var xAxis = scaleLinear()
            .domain([Math.min(...data.x) / 1000.0, Math.max(...data.x) / 1000.0])
            .range([0, width]);
    svg.append("g")
        .attr("transform", "translate(0, " + height + ")")
        .call(axisBottom(xAxis));

    var yAxis = scaleLinear()
            .domain([Math.min(...data.y), Math.max(...data.y)])
            .range([height, 0]);
    svg.append("g").call(axisLeft(yAxis));
    var scatterArea = svg.append("g");
    for (var i = 0; i < data.x.length; i++) {
        var cx = xAxis(data.x[i] / 1000.0);
        var cy = yAxis(data.y[i]);
        scatterArea.append("circle")
            .attr("cx", cx)
            .attr("cy", cy)
            .attr("r", 2.5)
            .style("fill", (opts && opts.color ? opts.color : '#3399CC'));
    }
    return data;
}
window.loadData = loadData;
window.drawGraph = drawGraph;